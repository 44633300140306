<template>
  <div id="app">
    <el-container>
      <el-header id="app-header" height="80px">
        <el-menu
          id="top-menu"
          mode="horizontal"
          :router="true"
          :default-active="activeLink"
        >
          <div class="logo">
            <img :src="logo" alt="Suisse Optical" />
          </div>
          <el-menu-item index="/">Configuratore</el-menu-item>

          <!-- Guest menu -->
          <el-menu-item
            v-show="userIsGuest"
            @click="loginDialogVisible = true"
          >Login</el-menu-item>

          <!-- User menu -->
          <el-menu-item
            v-show="!userIsGuest"
            @click="loginDialogVisible = true"
          >Account</el-menu-item>
          <el-menu-item v-show="!userIsGuest" index="/carrello">
            <el-badge :value="rowNumber">Carrello</el-badge>
          </el-menu-item>
          <el-menu-item v-show="!userIsGuest" index="/storico">Storico ordini</el-menu-item>
          <el-menu-item index="/store-locator">Store Locator</el-menu-item>
        </el-menu>
      </el-header>
      <el-container>
        <router-view />
      </el-container>
    </el-container>
    <login-dialog
      :visible="loginDialogVisible"
      @close="loginDialogVisible = false"
    />
  </div>
</template>

<script>
  import LoginDialog from "./components/LoginDialog";
  import { mapGetters, mapActions } from "vuex";
  import Logo from "./assets/images/S-O_younique.png";

  export default {
    data: function () {
      return {
        loginDialogVisible: false,
        logo: Logo,
        activeLink: null,
      };
    },
    components: {
      LoginDialog,
    },
    mounted: async function () {
      await this.getUserData();
      this.activeLink = this.$route.path;
    },
    methods: {
      ...mapActions("user", ["getUserData"]),
    },
    computed: {
      ...mapGetters({ userIsGuest: "user/isGuest" }),
      ...mapGetters("cart", ["rowNumber"]),
    },
    watch: {
      // eslint-disable-next-line no-unused-vars
      $route(to, from) {
        this.activeLink = to.path;
      },
    },
  };
</script>

<style lang="less">
  #app {
    font: normal normal 300 20px/24px Rubik;
    // font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  #app-header {
    padding: 0;
    #top-menu {
      background-color: #753bbd;
      height: 60px;
      margin-top: 20px;
      display: flex;
      justify-content: flex-end;
      padding-right: 5%;
      li {
        // font-size: 20px;
        height: 40px;
        line-height: 40px;
        margin-top: 10px;
      }

      .is-active {
        border: solid 2px;
        font-weight: 500;
      }
      .logo {
        display: flex;
        align-items: center;
        justify-content: center;
        top: -20px;
        left: 5%;
        position: absolute;
        width: 100px;
        width: 133px;
        border: solid @--color-primary 1px;
        margin-top: 2px;
        img {
          max-width: 100%;
        }
      }
    }
  }
</style>
