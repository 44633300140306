// import Vue from "vue";
import ajax from "../../plugins/ajax_request";

const state = () => ({
  cartRows: [],
  configurations: [],
  checkoutSuccess: false,
  checkingOut: false,
});

const getters = {
  canAddToCart: (state, getters, rootState, rootGetters) => {
    let guest = rootGetters["user/userIsGuest"];
    let configurationComplete = rootGetters.configurationComplete;
    if (configurationComplete && !guest) {
      return true;
    } else {
      return false;
    }
  },
  // eslint-disable-next-line no-unused-vars
  rowNumber: (state, getters, rootState, rootGetters) => {
    return state.cartRows.length;
  },
};

const mutations = {
  setCartRows(state, rows) {
    state.cartRows = rows;
  },
  /*Cart mutations*/

  emptyCart: (state) => {
    state.cartRows = [];
  },
  setCheckoutStatus: (state, status) => {
    state.checkoutSuccess = status;
  },
  setCheckingOut: (state, s) => {
    state.checkingOut = s;
  },
};

const actions = {
  // eslint-disable-next-line no-unused-vars
  addToCart({ commit, state, rootState }) {
    let rows = [];
    let row = {
      ItemCode: rootState.frontale.ItemCode,
      colore_frontale: rootState.colore.U_THE_COLORE,
      quantita: 1,
      note: rootState.note,
      TempleCode: rootState.coloreAste.ItemCode,
      FinishingTemple: rootState.finituraAste,
      calibro: rootState.calibro,
      naso: rootState.naso,
      finishing: rootState.finitura,
      asian_fitting: rootState.asianFitting ? 1 : 0,
      colore_nome:
        rootState.nome && rootState.nome.length > 0 ? rootState.coloreNome : "",
      font_nome: "Batang",
      nome: rootState.nome,
      coloreMetalloAste: rootState.coloreMetalloAste,
      nomeExtAstaDx: rootState.nomeExtAstaDx,
      fontNomeIntAstaDx: "Batang",
      coloreNomeExtAstaDx:
        rootState.nomeExtAstaDx && rootState.coloreNomeExtAstaDx.length > 0
          ? rootState.coloreNomeExtAstaDx
          : "",
      nomeIntAstaDx: rootState.nomeIntAstaDx,
      fontNomeExtAstaDx: "Batang",
      coloreNomeIntAstaDx:
        rootState.nomeIntAstaDx && rootState.coloreNomeIntAstaDx.length > 0
          ? rootState.coloreNomeIntAstaDx
          : "",
      // quantita: rootState.quantity,
    };
    rows.push(row);
    rootState.note = "";
    return ajax.addToCart(rows).then((response) => {
      let d = response.data;
      if (d.status.success) {
        // let gtagItems = [];
        // rows.forEach((row) => {
        //   let i = state.frontali.filter((frontale) => {
        //     return frontale.ItemCode == row.ItemCode;
        //   })[0];
        //   let temp = {
        //     id: i.ItemCode,
        //     name: i.ItemName,
        //     brand: i.U_THE_LINEA,
        //     variant: `${i.U_THE_MODELLO} ${row.calibro}/${row.naso} ${row.colore_frontale} - ${row.finishing}`,
        //     quantity: row.quantita,
        //     price: i.PRICE,
        //     aste: state.coloreAste.ItemName,
        //     coloreMetalloAste: state.coloreMetalloAste,
        //     finitura: state.finitura == "S" ? "LUCIDO" : "OPACO",
        //     asianFitting: state.asianFitting ? "SI" : "NO",
        //     coloreFrontale: row.colore_frontale,
        //     misura: `${row.calibro}/${row.naso}`,
        //     finituraAste: state.finituraAste == "S" ? "LUCIDO" : "OPACO",
        //     nomePersonalizzato: state.nome,
        //     coloreNomePersonalizzato: state.coloreNome,
        //   };
        //   gtagItems.push(temp);
        // });
        // gtag("event", "add_to_cart", { items: gtagItems });

        rootState.nome = "";
        rootState.coloreNome = "";
        rootState.nomeExtAstaDx = "";
        rootState.coloreNomeExtAstaDx = "";
        rootState.nomeIntAstaDx = "";
        rootState.coloreNomeIntAstaDx = "";
        commit("setCartRows", d.cart); //status.cartRows = d.cart;
      }
    });
  },
  addSampleToCart({ commit, state }, rows) {
    // let auth_key = Vue.$cookies.get("_identity");
    return ajax.addToCart(rows).then((response) => {
      let d = response.data;
      if (d.status.success) {
        state.nome = "";
        state.coloreNome = "";
        commit("setCartRows", d.cart); //status.cartRows = d.cart;
      }
    });
  },
  deleteCartRow(commit, id) {
    // let auth_key = Vue.$cookies.get("_identity");
    ajax.deleteCartRow(id).then((response) => {
      let d = response.data;
      if (d.status == true) {
        commit("setCartRows", d.cart); //status.cartRows = d.cart;
      }
    });
  },
  emptyCart(commit) {
    ajax.emptyUserCart().then((response) => {
      let d = response.data;
      if (d == true) {
        commit("emptyCart");
      }
    });
  },
  checkout(commit, note) {
    ajax.checkout(note).then((response) => {
      let d = response.data;
      if (parseInt(d.order_id) > 0) {
        commit("setCheckoutStatus", true);
        commit("setCheckingOut", false);
        commit("emptyCart");
      } else {
        commit("setCheckoutStatus", false);
      }
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
