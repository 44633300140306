<template>
  <!-- <div id="menu-laterale" :style="{ height: `${height - 20}px` }"> -->
  <div id="menu-laterale">
    <el-collapse v-if="confirm == false" @change="handleChange" accordion>
      <el-collapse-item name="front" class="main-item">
        <template slot="title">
          <div class="front-title main">
            <div class="content">
              <div
                class="rect"
                :class="{ isActive: menuItem.indexOf('front') >= 0 }"
              >
                <span
                  class="number"
                  :class="{ isActive: menuItem.indexOf('front') >= 0 }"
                  >1</span
                >
              </div>
              <span>CONFIGURA IL FRONTALE</span>
            </div>
            <div v-if="frontalError != ''" class="error main">
              {{ frontalError }}
            </div>
          </div>
        </template>
        <frontale
          @change="handleChange"
          @validate="validate('frontal')"
          :isActive="menuItem.indexOf('front') >= 0"
        />
      </el-collapse-item>
      <el-collapse-item name="temples" class="main-item">
        <template slot="title">
          <div class="temples-title main">
            <div class="content">
              <div
                class="rect"
                :class="{ isActive: menuItem.indexOf('temples') >= 0 }"
              >
                <span
                  class="number"
                  :class="{ isActive: menuItem.indexOf('temples') >= 0 }"
                  >2</span
                >
              </div>
              <span>CONFIGURA LE ASTE</span>
            </div>
            <div v-if="templesError != ''" class="error main">
              {{ templesError }}
            </div>
          </div>
        </template>
        <aste
          @change="handleChange"
          @validate="validate('temples')"
          :isActive="menuItem.indexOf('temples') >= 0"
        />
      </el-collapse-item>
      <nome-personalizzato :menuItem="menuItem" />
      <note :menuItem="menuItem" />
    </el-collapse>

    <riepilogo :configurations="configurations" v-else />

    <!-- Group button infondo al menu -->
    <el-button-group class="footerButton">
      <el-button
        v-if="confirm == false"
        class="footerButton footerButton1"
        type="default"
        @click="resetConfiguration"
        >RESET</el-button
      >
      <el-button
        v-else
        class="footerButton footerButton1"
        type="default"
        @click="modifyConfig"
        >MODIFICA</el-button
      >
      <el-popover
        popper-class="errors"
        ref="errorsPopover"
        placement="top"
        trigger="manual"
        title="Correggi i seguenti errori per proseguire"
        v-model="showPopoverErrors"
      >
        <div class="content">
          <ul>
            <li v-for="(component, i) in errorPopoverList" :key="i">
              {{ i | capitalize }}
              <br />
              <ul>
                <li v-for="(e, k) in component" :key="k">{{ e.message }}</li>
              </ul>
            </li>
          </ul>
        </div>
      </el-popover>
      <el-button
        class="footerButton footerButton1"
        type="default"
        :disabled="!canAddToCart"
        @mouseover.native="showPopoverErrors = !isEmpty(errorPopoverList)"
        @mouseleave.native="showPopoverErrors = false"
        @click="openShareDialog"
        >CONDIVIDI</el-button
      >
      <el-button
        v-if="confirm == false"
        class="footerButton footerButton1"
        type="default"
        :disabled="!canAddToCart || userIsGuest"
        v-popover:errorsPopover
        @mouseover.native="showPopoverErrors = !isEmpty(errorPopoverList)"
        @mouseleave.native="showPopoverErrors = false"
        @click="addConfig"
        >AGGIUNGI</el-button
      >
      <el-button
        v-else
        class="footerButton footerButton1"
        type="default"
        @click="primoDialog = true"
        >CONFERMA</el-button
      >
    </el-button-group>

    <!-- Dialog conferma -->
    <el-dialog
      class="confirm-dialog"
      title="SEI SICURO?"
      :visible.sync="primoDialog"
      width="500px"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button class="confirm-button" @click="primoDialog = false"
          >ANNULLA</el-button
        >
        <el-button class="confirm-button" @click="conferma">CONFERMA</el-button>
      </el-button-group>
    </el-dialog>

    <!-- Dialog articolo aggiunto -->
    <el-dialog
      class="confirm-dialog"
      width="550px"
      title="ARTICOLO AGGIUNTO AL CARRELLO"
      :visible.sync="secondoDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-button-group>
        <el-button class="confirm-button" @click="goToConfigurator"
          >VAI AL CONFIGURATORE</el-button
        >
        <el-button class="confirm-button" @click="goToCart"
          >VAI AL CARRELLO</el-button
        >
      </el-button-group>
    </el-dialog>

    <!-- Form per condivisione configurazione -->
    <el-dialog
      class="share-dialog"
      width="550px"
      title="CONDIVIDI"
      :visible.sync="shareDialog"
      :modal="false"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form
        label-position="top"
        :model="dynamicValidateForm"
        ref="dynamicValidateForm"
      >
        <el-form-item
          class="input-mail-from"
          prop="emailFrom"
          label="Email mittente"
          :rules="[
            { required: true, message: 'Campo obbligatorio', trigger: 'blur' },
            {
              type: 'email',
              message: 'Per favore inserire una mail valida',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input v-model="dynamicValidateForm.emailFrom"></el-input>
        </el-form-item>
        <el-form-item
          class="input-mail-to"
          prop="emailTo"
          label="Email destinatario"
          :rules="[
            { required: true, message: 'Campo obbligatorio', trigger: 'blur' },
            {
              type: 'email',
              message: 'Per favore inserire una mail valida',
              trigger: ['blur', 'change'],
            },
          ]"
        >
          <el-input v-model="dynamicValidateForm.emailTo"></el-input>
        </el-form-item>
        <el-input
          class="message-mail"
          type="textarea"
          :rows="5"
          placeholder="Messaggio"
          v-model="messageMail"
        ></el-input>
        <div class="campi-obblig">
          <span>* Campi obbligatori</span>
        </div>
        <el-form-item>
          <el-button-group>
            <el-button class="confirm-button" @click="shareDialog = false"
              >CHIUDI</el-button
            >
            <el-button class="confirm-button" @click="condividiConfig()"
              >INVIA</el-button
            >
          </el-button-group>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- Dialog configurazione inviata -->
    <el-dialog
      class="share-confirm-dialog"
      width="550px"
      title="CONFIGURAZIONE INVIATA"
      :visible.sync="shareConfirm"
      :modal="false"
      :show-close="true"
    >
      <div class="text-share-confirm-dialog">
        <span>Grazie per aver utilizzato il nostro configuratore!</span>
        <br />
        <span>
          L'email con il riepilogo dell'occhiale che hai configurato è stata
          inviata.
        </span>
        <br />
        <br />
        <span>
          Con questa informazione il tuo ottico di fiducia potrà realizzare il
          tuo occhiale personalizzato.
        </span>
        <br />
        <span>Se vuoi trovare il punto vendita S-O più vicino a te</span>
        <br />
        <!-- <el-link :underline="false" href="/store-locator">CLICCA QUI</el-link> -->
        <el-button
          @click="$router.push('/store-locator')"
          type="text"
          size="large"
          class="click-here"
          >CLICCA QUI</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
// import Aste from "./menu/Aste.vue";
// import Frontale from "./menu/Frontale.vue";

import Components from "./menu/index";
// import sender from "./menu/mailer";
import { mapGetters, mapActions, mapState, mapMutations } from "vuex";
import _ from "lodash";
// import * as THREE from "three";
// import jslinq from "jslinq";

export default {
  components: Components,
  filters: {
    capitalize(w) {
      return w.charAt(0).toUpperCase() + w.slice(1);
    },
  },
  data() {
    return {
      input: "",
      noteTextArea: "",
      width: 0,
      menuItem: "",
      height: 0,
      frontalError: "",
      templesError: "",
      showPopoverErrors: false,
      primoDialog: false,
      secondoDialog: false,
      shareDialog: false,
      emailFrom: "",
      emailTo: "",
      messageMail: "",
      shareConfirm: false,
      mailData: [],
      dynamicValidateForm: {
        emailFrom: "",
        emailTo: "",
      },
    };
  },

  mounted: function () {
    // this.width = window.innerWidth - 500;
    this.height = window.innerHeight - 80;
  },
  methods: {
    ...mapActions("cart", ["addToCart"]),
    ...mapActions(["sendSendGridMail", "setIdForUrl"]),
    ...mapMutations([
      "setConfirm",
      "saveConfiguration",
      "clearAllConfigurations",
      "setFrontal",
    ]),
    isEmpty(o) {
      return _.isEmpty(o);
    },
    handleChange(val) {
      this.menuItem = val;
      // if (this.menuItem == "front") {
      //   this.$store.state.animateToFront = true;
      // }
      // if (this.menuItem == "temples") {
      //   this.$store.state.animateToTemple = true;
      // }
    },
    validate(menu) {
      let haveTemplesErrors = false,
        haveFrontalErrors = false;
      for (let i = 0; i < this.componentsErrors.length; i++) {
        const element = this.componentsErrors[i];
        if (!_.isEmpty(element["temples"])) {
          haveTemplesErrors = true;
        }
        if (!_.isEmpty(element["frontal"])) {
          haveFrontalErrors = true;
        }
      }
      if (haveTemplesErrors && menu.indexOf("temples") >= 0) {
        this.templesError = "Configurazione non completa";
      } else if (!haveTemplesErrors) {
        this.templesError = "";
      }
      if (haveFrontalErrors && menu.indexOf("frontal") >= 0) {
        this.frontalError = "Configurazione non completa";
      } else if (!haveFrontalErrors) {
        this.frontalError = "";
      }
    },

    addConfig() {
      this.setConfirm();
      this.saveConfiguration();
    },
    modifyConfig() {
      this.setConfirm();
      this.clearAllConfigurations();
    },
    conferma() {
      this.primoDialog = false;
      this.secondoDialog = true;
      this.addToCart();
    },
    goToConfigurator() {
      this.secondoDialog = false;
      this.primoDialog = false;
      this.clearAllConfigurations();
      this.setConfirm();
      // this.$router.push("/configuratore");
    },

    goToCart() {
      this.clearAllConfigurations();
      this.setConfirm();
      this.$router.push("/carrello");
    },

    resetConfiguration() {
      // this.$store.state.frontale = "";
      this.$store.state.calibro = "";
      this.$store.state.naso = "";
      this.$store.state.asianFitting = false;
      this.$store.state.colore = "";
      this.$store.state.finitura = "S";
      this.$store.state.coloreAste = "";
      this.$store.state.coloreMetalloAste = "";
      this.$store.state.finituraAste = "S";
      this.$store.state.misuraAste = "";
      this.$store.state.nome = "";
      this.$store.state.fontNome = "Batang";
      this.$store.state.coloreNome = "";
      this.$store.state.nomeExtAstaDx = "";
      this.$store.state.fontNomeIntAstaDx = "Batang";
      this.$store.state.coloreNomeExtAstaDx = "";
      this.$store.state.nomeIntAstaDx = "";
      this.$store.state.fontNomeExtAstaDx = "Batang";
      this.$store.state.coloreNomeIntAstaDx = "";
      this.$store.state.note = "";
      this.$emit("resettedMaterial");
      this.setFrontal(this.$store.state.frontale);
    },

    condividiConfig() {
      // this.shareDialog = false;
      // this.shareConfirm = true;

      this.$refs.dynamicValidateForm.validate(async (valid) => {
        if (valid) {
          // alert("submit!");
          let data = {},
            emailFrom = this.dynamicValidateForm.emailFrom,
            emailTo = this.dynamicValidateForm.emailTo,
            isGmail = this.dynamicValidateForm.emailTo.includes("@gmail.com");

          let finFrontale =
              this.getActiveConfiguration.finitura == "S" ? "LUCIDO" : "OPACO",
            finAste =
              this.getActiveConfiguration.finituraAste == "S"
                ? "LUCIDO"
                : "OPACO",
            canvas = document.getElementsByTagName("canvas")[0],
            img = canvas.toDataURL("image/png"),
            colFrontale =
              this.getActiveConfiguration.colore.U_THE_COLORE.replace("PL", ""),
            colAste =
              this.getActiveConfiguration.coloreAste.U_THE_COLORE.replace(
                "PL",
                ""
              ),
            colEst = "",
            colInt = "";
          if (this.$store.state.colore.Category == "MULTISTRATO") {
            colEst =
              "https://media.thema-optical.com/colori_acetato/frontali/" +
              this.$store.state.colore.U_THE_COLORE +
              ".jpg";
            colInt =
              "https://media.thema-optical.com/colori_acetato/frontali/" +
              this.$store.state.colore.U_THE_COLORE +
              "_b.jpg";
          }

          data = {
            modello: this.getActiveConfiguration.frontale.ModelName,
            calibro: this.getActiveConfiguration.calibro,
            ponte: this.getActiveConfiguration.naso,
            coloreFrontale: colFrontale,
            finFrontale: finFrontale,
            misuraAste: this.getActiveConfiguration.misuraAste,
            coloreAste: colAste,
            finAste: finAste,
            personalizzazione: this.getActiveConfiguration.nome,
            colPers: this.getActiveConfiguration.coloreNome,
            note: this.getActiveConfiguration.note,
            message: this.messageMail,
            image: img,
            qrcode: await this.makeQRCode(this.$store.state.id_for_url),
            qrcodeUrl: this.$store.state.qrcodeUrl,
            colEst: colEst,
            colInt: colInt,
            id: this.$store.state.id_for_url,
            isGmail: isGmail,
          };

          // eslint-disable-next-line no-undef
          this.sendSendGridMail({ data, emailFrom, emailTo });
          this.shareDialog = false;
          this.shareConfirm = true;
        } else {
          alert("Email non inviata correttamente");
          // console.log("error submit!!");
          // return false;
        }
      });
    },
    // },

    openShareDialog() {
      this.messageMail = this.$store.state.note;
      this.setIdForUrl();
      setTimeout(() => {
        this.shareDialog = true;
      }, 1500);
      this.$emit("setPositionForPrint");
      this.$store.state.qrcode = this.makeQRCode(this.$store.state.id_for_url);
    },

    makeQRCode(id) {
      let QRCode = require("qrcode");
      let url = window.location.href;
      let id1 = url.split("/");
      if (parseInt(id1[id1.length - 1]) > 0) {
        id1.pop();
        this.$store.state.qrcodeUrl = id1.join("/") + "/" + id;
        return QRCode.toDataURL(id1.join("/") + "/" + id);
      } else {
        this.$store.state.qrcodeUrl = window.location.href + id;
        return QRCode.toDataURL(window.location.href + id);
      }
    },
  },
  computed: {
    ...mapGetters(["componentsErrors", "getActiveConfiguration"]),
    ...mapGetters("cart", ["canAddToCart"]),
    ...mapGetters("user", { userIsGuest: "isGuest" }),
    ...mapState({ confirm: "confirm", configurations: "configurations" }),
    errorPopoverList() {
      let errors = {};
      for (let i = 0; i < this.componentsErrors.length; i++) {
        const element = this.componentsErrors[i];
        if (!_.isEmpty(element["temples"])) {
          errors.aste = [];
          for (let y = 0; y < element["temples"].length; y++) {
            const el = element["temples"][y];
            errors.aste.push(el);
          }
        }
        // }

        if (!_.isEmpty(element["frontal"])) {
          errors.frontali = [];
          for (let y = 0; y < element["frontal"].length; y++) {
            const el = element["frontal"][y];
            errors.frontali.push(el);
          }
        }
      }

      return errors;
    },
  },
  watch: {},
};
</script>

<style lang="less">
.el-container > .el-main {
  padding: 0px;
}

div[class*="-title"].main {
  .content {
    align-items: center;
    justify-content: normal !important;
    .rect {
      height: 18px;
      width: 18px;
      // aspect-ratio: 1;
      transform: rotate(45deg);
      background: @--color-black;
      border: 1px solid @--color-primary;
      margin-right: 10px;
      margin-left: 15px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.isActive {
        background: @--color-text-primary;
        border: 1px solid @--color-text-primary;
      }
    }
  }
  .error.main {
    padding-left: 45px;
  }
  .number {
    transform: rotate(-45deg);
    color: @--color-primary;
  }
}

@media (max-width: 992px) {
  #menu-laterale {
    height: ~"calc(50vh - 100px)";
  }
}

@media (min-width: 993px) {
  #menu-laterale {
    height: ~"calc(100vh - 100px)";
  }
}

#menu-laterale {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: @--background-color-base;
  margin-top: 20px;

  .confirm-dialog {
    padding-top: 10%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);
    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;
      .el-dialog__header {
        margin-top: 10%;
        span {
          color: @--color-primary;
          font-size: 16px;
        }
      }
      .el-dialog__body {
        padding: 50px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;
        .el-button-group {
          width: 100%;
        }
      }
    }
  }

  .share-dialog {
    padding-top: 5%;
    font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);
    .el-dialog {
      color: @--color-primary;
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;

      .el-dialog__body {
        padding: 50px 0px 0px 0px;
        margin-left: -1px;
        margin-right: -2px;
        .input-mail-from {
          width: 75%;
          padding-bottom: 3%;
          display: flex;
          flex-direction: column;
          padding-left: 13%;
        }
        .input-mail-to {
          width: 75%;
          padding-bottom: 5%;
          display: flex;
          flex-direction: column;
          padding-left: 13%;
        }
        .message-mail {
          width: 75%;
          padding-bottom: 1%;
        }
        .campi-obblig {
          display: flex;
          justify-content: space-around;
          width: 50%;
          padding-bottom: 5%;
        }
        .el-button-group {
          width: 100%;
        }
        .el-form-item {
          margin-bottom: 0px !important;
          .el-form-item__label {
            padding: 0px;
            line-height: 25px;
          }
          .el-form-item__label:before {
            color: @--color-text-regular;
          }
        }
      }
    }
  }

  .confirm-button {
    width: 50%;
    font-size: 16px;
    margin-bottom: -1px;
  }
  .share-confirm-dialog {
    padding-top: 5%;
    // font-weight: 500;
    background-color: rgba(0, 0, 0, 0.59);

    .el-dialog {
      border: 1px solid @--color-primary;
      box-shadow: 0 0px 0px rgb(0 0 0);
      border-radius: 0px;

      .el-dialog__header {
        font-weight: 500;
        padding-top: 10%;
      }

      .el-dialog__body {
        color: @--color-primary;
        padding: 40px 20px 50px 20px;
        margin-left: -1px;
        margin-right: -2px;
        word-break: normal;
        .el-link--inner {
          color: @--color-primary;
          font-weight: 700;
        }
      }
    }
  }

  .confirm-button {
    width: 50%;
    font-size: 16px;
    margin-bottom: -1px;
  }
  .menu-content {
    display: flex;
    padding: 8px 0;
    align-items: center;

    &.input-name,
    &.input-note {
      padding-left: 45px;
      padding-right: 26px;
    }
  }

  .el-aside {
    margin: 0px;
    padding: 0px;
  }

  .el-collapse-item__content {
    padding-bottom: 0px;
  }

  .el-collapse-item .el-collapse .el-collapse-item__header {
    padding-left: 45px;
  }
  .el-collapse-item__header {
    height: 70px;
  }

  i.el-collapse-item__arrow.el-icon-arrow-right:before {
    padding-right: 15px;
    content: "\e6d9";
  }

  .el-collapse-item__arrow.el-icon-arrow-right.is-active {
    transform: rotate(0deg);
    color: @--color-text-primary;

    &:before {
      content: "\e6d8";
    }
  }

  .el-card__body {
    margin: 0px;
    padding: 0px;
  }

  .divGroupButton {
    padding: 0px 23px 15px 45px;
  }

  .persGroupButton {
    padding: 0px 0px 10px 0px;
    width: 100%;
  }

  .footerButton {
    position: sticky;
    bottom: 0px;
    &.footerButton1 {
      width: 33.33%;
    }
  }

  .text-share-confirm-dialog {
    .click-here:hover {
      background-color: transparent !important;
      color: @--color-primary;
    }
  }
}
.el-popover.errors {
  @bgColor: lighten(@--color-text-danger, 27%);
  background: @bgColor;
  border-color: @bgColor;
  color: @--color-text-danger;
  .el-popover__title {
    color: @--color-text-danger;
  }
  & .popper__arrow:after {
    // background: @bgColor;
    border-top-color: @bgColor !important;
    color: @--color-text-danger;
  }
}

.confirm-title {
  background-color: @--color-primary;
  color: @--color-text-primary;
  font-weight: bold;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50px;
}
</style>
