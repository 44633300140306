import ajax from "../plugins/ajax_request";

export default {
  getCountryStoresActive() {
    return ajax.getCountryStoresActive();
  },
  // eslint-disable-next-line no-unused-vars
  getStores({ state }, cc) {
    return ajax.getStores(cc);
  },
  // eslint-disable-next-line no-unused-vars
  sendSendGridMail: async function(state, { data, emailFrom, emailTo }) {
    // eslint-disable-next-line no-unused-vars
    let res = await ajax.sendSendGridMail(
      data,
      emailFrom,
      emailTo,
      process.env.VUE_APP_SENDGRID_SHARE_MAIL_TEMPLATEID,
      process.env.VUE_APP_SENDGRID_API_KEY
    );
    // console.log("SEND MAIL", res);
  },

  setIdForUrl: async function({ state, getters }) {
    let json = JSON.stringify(getters.getActiveConfiguration);
    let res = await ajax.setCustomConfigurations(json);
    state.id_for_url = res.data;
    // state.qrcodeUrl = "http://localhost:8080/" + res.data;
    // state.qrcode = await getters.makeQRCode(res.data);
  },

  // eslint-disable-next-line no-unused-vars
  getCustomConfigurations: async function({ state, commit }, id) {
    let res = await ajax.getCustomConfigurations(id);
    // console.log("getCustomConfigurations", res.data);
    commit("restoreJsonConfiguration", res.data);
  },
};
