<template>
  <div id="riepilogo">
    <el-card shadow="never" class="r-card">
      <div slot="header" class="confirm-title">
        <span>CONFERMA PER PROCEDERE</span>
      </div>
      <div class="confirm">
        <el-col class="column-sx">
          <el-row>MODELLO:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].frontale.ModelName }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>CALIBRO-PONTE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].calibro }}/{{ this.configurations[0].naso }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>COLORE FRONTALE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].colore.U_THE_COLORE.replace('PL', '') }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>FINITURA FRONTALE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row v-if="this.configurations[0].finitura == 'S'">LUCIDO</el-row>
          <el-row v-else-if="this.configurations[0].finitura == 'M'">OPACO</el-row>
          <el-row v-else>NATURALE</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>MISURA ASTE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].misuraAste }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>COLORE ASTE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].coloreAste.U_THE_COLORE.slice(2) }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>FINITURA ASTE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row v-if="this.configurations[0].finituraAste == 'S'">LUCIDO</el-row>
          <el-row v-else>OPACO</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>PERSONALIZZAZIONE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row v-if="this.configurations[0].nome !== ''">{{ this.configurations[0].nome }}</el-row>
          <el-row v-if="this.configurations[0].nomeExtAstaDx !== ''">{{ this.configurations[0].nomeExtAstaDx }}</el-row>
          <el-row v-if="this.configurations[0].nomeIntAstaDx !== ''">{{ this.configurations[0].nomeIntAstaDx }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>COLORE PERSONALIZZAZIONE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row v-if="this.configurations[0].coloreNome !== ''">{{ this.configurations[0].coloreNome.toUpperCase()
          }}</el-row>
          <el-row v-if="this.configurations[0].coloreNomeExtAstaDx !== ''">{{
            this.configurations[0].coloreNomeExtAstaDx.toUpperCase() }}</el-row>
          <el-row v-if="this.configurations[0].coloreNomeIntAstaDx !== ''">{{
            this.configurations[0].coloreNomeIntAstaDx.toUpperCase() }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>POSIZIONE PERSONALIZZAZIONE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row v-if="this.configurations[0].nome !== ''">FRONTALE</el-row>
          <el-row v-if="this.configurations[0].nomeExtAstaDx !== ''">EST. ASTA DESTRA</el-row>
          <el-row v-if="this.configurations[0].nomeIntAstaDx !== ''">TOP ASTA DESTRA</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>NOTE:</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>{{ this.configurations[0].note }}</el-row>
        </el-col>
        <el-col class="column-sx">
          <el-row>QUANTITA':</el-row>
        </el-col>
        <el-col class="column-dx">
          <el-row>
            <!-- <el-input-number
              class="input-quantity"
              v-model="quantity"
              controls-position="right"
              :min="1"
              :max="10"
              size="mini"
            ></el-input-number>-->
            1
          </el-row>
        </el-col>

        <!-- <el-table :data="this.configurations">
          <div class="column-sx">
            <el-table-column>
              <el-col>MODELLO:</el-col>
              <el-col>CALIBRO:</el-col>
            </el-table-column>
          </div>
          <el-table-column>
            <template slot-scope="scope">
              <el-col>{{ scope.row.frontale.ModelName }}</el-col>
              <el-col>{{ scope.row.calibro }}/{{ scope.row.naso }}</el-col>
            </template>
          </el-table-column>
        </el-table>-->
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapMutations } from "vuex";
export default {
  props: ["configurations"],
  methods: {
    ...mapMutations(["setQuantity"]),
  },
  computed: {
    quantity: {
      get() {
        return this.$store.state.quantity;
      },
      set(val) {
        this.setQuantity(val);
      },
    },
  },
};
</script>

<style lang="less" >
#riepilogo {
  .input-quantity {
    width: 75px;
    // height: 25px;
  }

  .r-card>.el-card__header {
    padding-right: 0px;
  }

  // .el-table__body {
  //   width: 100%;
  //   // padding-top: 60px;
  //   // padding-left: 40px;
  // }

  .confirm {
    margin-top: 30px;
    margin-left: 40px;
    width: ~"calc(100% - 23px)";
    // background-color: @--color-primary;
    color: @--color-primary;
    // font-weight: bold;
    font-size: 16px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    // height: 50px;
  }

  .column-sx {
    font-weight: 500;
    width: 65%;
    display: flex;
    padding-bottom: 10px;
  }

  .column-dx {
    // font-weight: bold;
    width: 35%;
    display: flex;
    word-break: break-all;
  }
}
</style>