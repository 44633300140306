export default [
  {
    nome: 'rosa',
    max: 345,
    min: 300,
    style: {
      'background': '#ff00ff',
    }
  },
  {
    nome: 'viola',
    max: 299,
    min: 239,
    style: {
      'background': '#8000ff',
    }
  },
  {
    nome: 'blue',
    max: 238,
    min: 190,
    style: {
      'background': '#0000ff',
    }
  },
  {
    nome: 'verde',
    max: 189,
    min: 75,
    style: {
      'background': '#00ff00',
    }
  },
  {
    nome: 'giallo',
    max: 74,
    min: 48,
    //background:'#ffff00',
    style: {
      'background': '#ffff00'
    },
  },
  {
    nome: 'arancione',
    max: 47,
    min: 14,
    style: {
      'background': '#ff8000',
    }
  },
  {
    nome: 'rosso',
    max: 13,
    min: 0,
    style: {
      'background': '#ff0000',
    }
  },
  {
    nome: 'bianco/nero',
    max: -1,
    min: -2,
    style: {
      'background': 'linear-gradient(to bottom right, #FFFFFF 50%, #000000 50%)',
      'border-top-color': 'black',
      'border-left-color': 'black',
    }
  },
]