export default [
  {
    model: "SO-001",
    height: 47.8,
    angle: 13,
  },
  {
    model: "SO-006",
    height: 48,
    angle: 12,
  },
  {
    model: "SO-007",
    height: 50.5,
    angle: 8,
  },
  {
    model: "SO-008",
    height: 47.4,
    angle: 12,
  },
  {
    model: "SO-010",
    height: 48.5,
    angle: 13,
  },
  {
    model: "SO-012",
    height: 43.3,
    angle: 12,
  },
  {
    model: "SO-014",
    height: 45.3,
    angle: 13,
  },
  {
    model: "SO-015",
    height: 47.3,
    angle: 12,
  },
  {
    model: "SO-016",
    height: 49.1,
    angle: 11,
  },
  {
    model: "SO-017",
    height: 45.0,
    angle: 9,
  },
  {
    model: "SO-022",
    height: 53.6,
    angle: 8,
  },
  {
    model: "SO-023",
    height: 40.9,
    angle: 9,
  },
  {
    model: "SO-024",
    height: 49.2,
    angle: 10,
  },
  {
    model: "SO-025",
    height: 46.2,
    angle: 8,
  },
  {
    model: "SO-026",
    height: 47.1,
    angle: 7,
  },
  {
    model: "SO-027",
    height: 51.4,
    angle: 10,
  },
  {
    model: "SO-028",
    height: 52.6,
    angle: 10,
  },
  {
    model: "SO-029",
    height: 47.42,
    angle: 10,
  },
  {
    model: "SO-030",
    height: 54.43,
    angle: 10,
  },
  {
    model: "SO-031",
    height: 53.14,
    angle: 10,
  },
  {
    model: "SO-032",
    height: 49.73,
    angle: 10,
  },
  {
    model: "SO-033",
    height: 47.82,
    angle: 10,
  },
  {
    model: "SO-034",
    height: 49.43,
    angle: 10,
  }
];
