import aste from "./Aste";
import frontale from "./Frontale";
import nomePersonalizzato from "./NomePersonalizzato";
import note from "./Note";
import riepilogo from "./Riepilogo";
export default {
  aste,
  frontale,
  nomePersonalizzato,
  note,
  riepilogo,
};
