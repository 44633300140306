<template>
  <div id="canvas-container" style="margin: auto">
    <iframe id="print-frame" frameborder="0"></iframe>

    <!-- <el-dialog
      :visible.sync="isLoading"
      persistent
      :overlay="false"
      width="300px"
      :modal="false"
      :show-close="false"
      style="padding: 0px"
    >
      
    </el-dialog>-->
    <el-card
      class="loadingCard"
      v-if="isLoading == true"
      v-loading="loading"
      :element-loading-text="`Caricamento del modello ${getActiveConfiguration.frontale.ModelName}`"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(255, 255, 255, 1)"
    >
      <!-- <el-progress
          type="line"
          :text-inside="true"
          :stroke-width="20"
          :percentage="loadingGeometry"
          color="@--color-primary"
      ></el-progress>-->
      <div style="height: 40px">
        <!-- Caricamento
          <br />
        <b>{{ getActiveConfiguration.frontale.ModelName }}</b>-->
      </div>
    </el-card>
    <div id="gui"></div>
    <div id="popoverMultistrato">
      <el-popover
        visible-arrow="false"
        popper-class="pop-multistrato"
        trigger="manual"
        v-model="dialogMultistrato"
        placement="bottom"
      >
        <el-card>
          <div slot="header">
            <span>PER I COLORI MULTISTRATO L'ANTEPRIMA NON E' DISPONIBILE</span>
          </div>
          <div class="colore-esterno">
            <span>COLORE ESTERNO</span>
            <el-image
              :src="
                'https://media.thema-optical.com/colori_acetato/frontali/' +
                this.$store.state.colore.U_THE_COLORE +
                '.jpg'
              "
              fit="scale-down"
            ></el-image>
          </div>
          <div class="colore-interno">
            <span>COLORE INTERNO</span>
            <el-image
              :src="
                'https://media.thema-optical.com/colori_acetato/frontali/' +
                this.$store.state.colore.U_THE_COLORE +
                '_b.jpg'
              "
              fit="scale-down"
            ></el-image>
          </div>
        </el-card>
      </el-popover>
    </div>
  </div>
</template>

<script>
// import ajax from '../plugins/ajax_request'
import { mapGetters, mapMutations, mapState, mapActions } from "vuex";
import * as Canvas from "./canvas/index";
import * as THREE from "three";
import "../../plugins/OrbitControls";
import "../../plugins/BufferGeometryUtils";
import "../../plugins/DRACOLoader.js";
import modelsHeight from "./canvas/osix_models_height.js";
import * as Metal from "./canvas/metal_materials.js";
import PlasticMaterial from "./canvas/plastic_materials.js";
// import methods from "../canvas/methods.js";
// import computed from "../canvas/computed.js";
// import watch from "../canvas/watch.js";
// import * as dat from "dat.gui";
// import { LightShadow } from 'three'
// import side from "../../assets/map/px.png";
// import top from "../../assets/map/py.png";
// import bottom from "../../assets/map/ny.png";

export default {
  props: ["setCanvasPos", "resetMaterial"],
  data() {
    return {
      loading: true,
      publicPath: process.env.BASE_URL,
      light: {},
      point_light1: {},
      point_light2: {},
      point_light3_1: {},
      point_light3_2: {},
      point_light3_3: {},
      // id_for_url: "",
      // qrcode: "",
      scene: {},
      plane: {},
      loader: {},
      texture_loader: {},
      render: {},
      camera: {},
      cubeCamera1: {},
      cubeCamera2: {},
      controls: {},
      spot_light_top: {},
      light_intensity: 1,
      front: {}, //modello
      front_material: {},
      temple_dx: {}, //asta_dx
      temple_sx: {}, //asta_sx
      temples: {},
      temples_material: {},
      pin_dx: {}, //asta_sx
      pin_sx: {}, //asta_sx
      pins_material: Metal.metal_silver,
      models_size: modelsHeight,
      width: 0,
      height: 0,
      offsetX: 30,
      rotationX: -1.63,
      offsetY: 1,
      rotationY: 0, //-1.63,
      offsetZ: 90,
      rotationZ: 0, //-0.35,
      componentsCount: 5,
      componentsLoaded: 0,
      loadingGeometry: 0, // % caricamento
      startLoading: false, // definisce quando deve partire il dialog di caricamento geometrie
      updateTemplesGeometry: false, //definisce quando è necessario aggiornare le geometrie delle aste
      plastic_lucid_settings: {
        roughness: 0.3,
        coatRoughness: 0,
        coat: 1,
        envMapIntensity: 0.8,
      },
      plastic_matt_settings: {
        roughness: 0.5,
        coatRoughness: 0.4,
        coat: 0.5,
        envMapIntensity: 0,
      },
      // dialogMultistrato: false,
    };
  },
  async mounted() {
    this.scene = new THREE.Scene();
    let fog = new THREE.Fog(0xf4f4f4, 450, 690);
    this.scene.fog = fog;
    this.scene.position.x = -30;

    this.texture_loader = new THREE.TextureLoader();

    this.render = new THREE.WebGLRenderer({
      antialias: true,
      depthWrite: false,
      preserveDrawingBuffer: true,
      powerPreference: "high-performance",
    });

    var fMaterial = new PlasticMaterial();
    var tMaterial = new PlasticMaterial();
    this.front_material = await fMaterial.getMaterial(
      this.plastic_lucid_settings.envMapIntensity
    );
    this.temples_material = await tMaterial.getMaterial(
      this.plastic_lucid_settings.envMapIntensity
    );

    // this.scene.background = this.front_material[0].envMap;

    // var imagePrefix = "../../images/";
    // var directions = [side, side, top, bottom, side, side]; //Using the same images for the walls
    // var imageSuffix = ".jpg";
    // var skyGeometry = new THREE.BoxGeometry(3000, 3000, 3000);

    // var materialArray = [];
    // for (var i = 0; i < 6; i++)
    //   // materialArray.push(
    //   //   new THREE.MeshBasicMaterial({
    //   //     // IMPLENTAZIONE SKYBOX DI 6 IMG
    //   //     map: THREE.ImageUtils.loadTexture(directions[i]),
    //   //     // map: THREE.ImageUtils.loadTexture( background ),
    //   //     side: THREE.BackSide,
    //   //   })
    //   // );
    //   var skyMaterial = new THREE.MeshFaceMaterial(materialArray);
    // var skyBox = new THREE.Mesh(skyGeometry, skyMaterial);
    // this.scene.add(skyBox);

    this.init();
    this.getQRCode();
    this.controls.update();
    if (this.frontModel) {
      this.loadFront();
      // this.loadTemples();
    }

    // this.animate();
    this.doRender();
  },
  computed: {
    ...mapGetters(["frontModel", "frontColor", "frontSize", "templesColor"]),
    ...mapState(["coloreMetalloAste", "finitura", "finituraAste", "frontali"]),
    ...mapGetters({
      getActiveConfiguration: "getActiveConfiguration",
    }),
    ...Canvas.computed,
  },
  methods: {
    ...mapMutations([
      "stopLoadingGeometry",
      "restoreJsonConfiguration",
      "setFrontal",
    ]),
    ...mapActions(["getCustomConfigurations"]),

    ...Canvas.methods,
  },
  watch: Canvas.watch,
};
</script>

<style  lang="less">
#gui {
  position: absolute;
  top: 200px;
  left: 0px;
  z-index: 10;
}

#canvas-container {
  .loadingCard {
    width: 300px;
    position: absolute !important;
    top: 30%;
    left: 30%;
  }

  margin: auto;

  canvas {
    transition: all 0.6s ease 0s;
  }

  #print-frame {
    position: absolute;
    z-index: -20;
  }
}

#popoverMultistrato {
  @media (max-width: 992px) {
    .pop-multistrato {
      right: 0%;
      bottom: 40%;
      width: 45%;

      .el-card {
        .el-card__header {
          padding: 8px;
        }
      }
      .colore-esterno {
        span {
          padding-top: 10px;
          padding-bottom: 0px;
        }
        .el-image {
          padding-bottom: 0px;
        }
      }
      .colore-interno {
        span {
          padding-top: 10px;
          padding-bottom: 0px;
        }
        .el-image {
          padding-bottom: 0px;
        }
      }
    }
  }

  @media (min-width: 993px) {
    .pop-multistrato {
      width: 25%;
      bottom: 5%;
      right: 50%;

      .el-card {
        .el-card__header {
          padding: 15px;
        }
      }

      .colore-esterno {
        span {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        .el-image {
          padding-bottom: 5px;
        }
      }
      .colore-interno {
        span {
          padding-top: 20px;
          padding-bottom: 10px;
        }
        .el-image {
          padding-bottom: 5px;
        }
      }
    }
  }
  .pop-multistrato {
    padding: 0%;
    .el-card {
      border-radius: 0%;
      border: solid 1px @--color-primary;
      .el-card__header {
        font-weight: bold !important;
        width: 100%;
        // padding: 15px;
        padding-left: 5% !important ;
        background-color: @--color-primary !important;
        color: @--color-text-primary;
        display: flex;
        word-break: normal !important;
      }
      .el-card__body {
        display: flex;
        padding: 0px;
      }
    }

    .colore-esterno {
      width: ~"calc(50% - 1px)";
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-around;
      justify-content: space-between;
      border-right: solid 1px @--color-primary;

      span {
        // padding-top: 20px;
        // padding-bottom: 10px;
        color: @--color-primary;
        font-weight: 500;
        word-break: normal !important;
        text-align: center;
      }
      .el-image {
        // padding-bottom: 5px;
        width: 120px !important;
        height: 100px;
      }
    }
    .colore-interno {
      width: 50%;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: space-around;
      justify-content: space-between;

      span {
        // padding-top: 20px;
        // padding-bottom: 10px;
        color: @--color-primary;
        font-weight: 500;
        word-break: normal !important;
        text-align: center;
      }

      .el-image {
        // padding-bottom: 5px;
        width: 120px !important;
        height: 100px;
      }
    }
  }
}
</style>
