<template>
  <el-dialog
    id="login-dialog"
    :title="title"
    :visible.sync="loginDialogVisible"
    width="500px"
    v-loading="isLoadingUserData"
    element-loading-text="Loading..."
    element-loading-spinner="el-icon-loading"
    @close="$emit('close')"
  >
    <el-main v-if="userIsGuest">
      <el-form :model="loginForm" ref="loginForm" :rules="loginFormRules">
        <el-form-item prop="username" :error="genericLoginError">
          <el-input placeholder="Email" v-model="loginForm.username"></el-input>
        </el-form-item>
        <el-form-item prop="password" :error="genericLoginError">
          <el-input
            placeholder="Password"
            v-model="loginForm.password"
            type="password"
          ></el-input>
        </el-form-item>
      </el-form>
    </el-main>
    <el-main v-else>
      <ul id="user-list-data">
        <li><b>NOME UTENTE</b>: {{ user.ragioneSociale }}</li>
        <li><b>EMAIL</b>: {{ user.email }}</li>
        <li><b>INDIRIZZO</b>: {{ user.street }}, {{ user.city }}</li>
        <li><b>P.IVA</b>: {{ user.partitaIva }}</li>
      </ul>
    </el-main>
    <div class="login-dialog-footer" slot="footer">
      <el-button-group>
        <el-button type="primary" plain @click="loginDialogVisible = false"
          >CHIUDI</el-button
        >
        <el-button v-if="userIsGuest" type="primary" plain @click="validate()"
          >LOGIN</el-button
        >
        <el-button v-else type="primary" plain @click="logout()"
          >LOGOUT</el-button
        >
      </el-button-group>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";

export default {
  props: ["visible"],
  data: function () {
    return {
      // loginDialogVisible: this.visible,
      loginForm: {
        username: "",
        password: "",
      },
      loginFormRules: {
        username: [
          {
            required: true,
            message: "Il codice cliente è obbligatorio",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "La password è obbligatoria",
            trigger: "blur",
          },
        ],
      },
      isLoadingUserData: false,
      genericLoginError: "",
    };
  },
  methods: {
    validate: async function () {
      this.isLoadingUserData = true;
      let valid = await this.$refs["loginForm"].validate();
      if (valid) {
        // eslint-disable-next-line no-unused-vars
        let r = await this.login({
          username: this.loginForm.username,
          password: this.loginForm.password,
        });
        if (this.userIsGuest) {
          if (r.data.error) {
            this.genericLoginError = r.data.msg;
          } else {
            this.genericLoginError = "Username o password errati";
          }
        }
      }
      this.isLoadingUserData = false;
    },
    ...mapActions("user", ["login", "logout"]),
  },
  computed: {
    ...mapGetters("user", { userIsGuest: "isGuest" }),
    ...mapState("user", ["user"]),
    loginDialogVisible: {
      get: function () {
        return this.visible;
      },
      set: function (value) {
        if (value == false) {
          this.$emit("close");
        }
      },
    },
    title() {
      return this.userIsGuest ? "INSERISCI I TUOI DATI" : "ACCOUNT";
    },
  },
};
</script>

<style lang="less" >
#login-dialog {
  #user-list-data {
    list-style-type: none;
    text-align: left;
    li {
      margin: 8px auto;
    }
  }
  .el-dialog__footer {
    padding: 0;
    .login-dialog-footer {
      .el-button-group {
        min-width: 100%;
        .el-button {
          min-width: 50%;
        }
      }
    }
  }
}
</style>