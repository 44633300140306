<template>
  <el-collapse-item name="name" id="personal-name" class="main-item">
    <template slot="title">
      <div class="name-title main">
        <div class="content">
          <div class="rect" :class="{ isActive: isActive }">
            <span class="number" :class="{ isActive: isActive }">3</span>
          </div>
          <span>CONFIGURA IL NOME</span>
        </div>
      </div>
    </template>
    <div class="input-name menu-content">
      <el-card shadow="never" id="personal-name-card">
        <!-- <img :src="preview" alt="" class="preview" /> -->
        <div class="personal-name-colors-wrapper">
          <div style="margin: 20px 0px">
            <el-button-group>
              <el-button
                :class="{isActive: incisione == 'incisFrontale' }"
                type="default"
                @click="setNomeIncis('incisFrontale')"
              >FRONTALE</el-button>
              <el-button
                :class="{isActive: incisione == 'incisEstAstaDx' }"
                type="default"
                @click="setNomeIncis('incisEstAstaDx')"
              >ESTERNO ASTA DESTRA</el-button>
              <el-button
                :class="{isActive: incisione == 'incisTopAstaDx' }"
                type="default"
                @click="setNomeIncis('incisTopAstaDx')"
              >TOP ASTA DESTRA</el-button>
            </el-button-group>
            <!-- <el-radio-group v-model="incisione" size="medium">
              <el-radio-button
                label="incisFrontale"
                @click="setNomeIncis()"
              >Frontale</el-radio-button>
              <el-radio-button
                label="incisEstAstaDx"
                @click="setNomeIncis()"
              >Asta destra</el-radio-button>
              <el-radio-button
                label="incisTopAstaDx"
                @click="setNomeIncis()"
              >Asta destra top</el-radio-button>
            </el-radio-group>-->
          </div>
          <div
            class="color-name-pers"
            v-for="(color, i) in colors"
            :key="i"
            :class="{
              oro: color.name == 'oro',
              argento: color.name == 'argento',
              bordered: color.name == 'bianco',
              nc: color.name == 'nessun-colore',
              active: color.colorCode == activeColor.colorCode
            }"
            :style="{ backgroundColor: color.colorCode }"
            @click="setActiveColor(color)"
          >
            <span class="no-colore" v-if="color.name == 'nessun-colore'">
              {{
              color.colorClass.toUpperCase()
              }}
            </span>
            <!-- <span v-if="color.colorCode == activeColor.colorCode">
              {{
              color.name.toUpperCase()
              }}
            </span>
            <span
              class="hover transparent"
              v-else
              @click="setTemplesColor(color)"
            >
              {{
              color.name.toUpperCase()
              }}
            </span>-->
          </div>
        </div>
        <frontal-dx
          v-if="incisione == 'incisFrontale'"
          class="preview"
          :name="testo"
          :color="activeColor"
        ></frontal-dx>
        <ext-asta-dx-preview
          v-if="incisione == 'incisEstAstaDx'"
          class="preview"
          :name="testo"
          :color="activeColor"
        ></ext-asta-dx-preview>
        <preview-asta-dx-top
          v-if="incisione == 'incisTopAstaDx'"
          class="preview"
          :name="testo"
          :color="activeColor"
        ></preview-asta-dx-top>
        <el-input
          v-if="incisione == 'incisTopAstaDx' || incisione == 'incisFrontale'"
          maxlength="10"
          show-word-limit
          placeholder="Inserire il testo"
          v-model="testo"
          style="margin-top: 20px"
        ></el-input>
        <el-input
          v-if="incisione == 'incisEstAstaDx'"
          maxlength="25"
          show-word-limit
          placeholder="Inserire il testo"
          v-model="testo"
          style="margin-top: 20px"
        ></el-input>
      </el-card>
    </div>
  </el-collapse-item>
</template>

<script>
  // eslint-disable-next-line no-unused-vars
  import { mapMutations, mapState } from "vuex";
  // import preview from "../../../assets/personalizations/asta_sx_top.svg";
  import FrontalDx from "./FrontaleDx.vue";
  import ExtAstaDxPreview from "./ExtAstaDxPreview.vue";
  import PreviewAstaDxTop from "./AnteprimaAstaDxTop";
  // eslint-disable-next-line no-unused-vars
  import _ from "lodash";
  // eslint-disable-next-line no-unused-vars
  import jslinq from "jslinq";

  export default {
    props: ["menuItem"],
    components: {
      FrontalDx,
      ExtAstaDxPreview,
      PreviewAstaDxTop,
    },
    data: function () {
      return {
        incisione: "incisFrontale",
        // nome: "",
        // colore: "",
        activeColor: {},
        colors: [
          {
            name: "nero",
            colorCode: "#212121",
            colorClass: "grey darken-4",
            checkColor: "white",
          },
          {
            name: "bianco",
            colorCode: "#fafafa",
            colorClass: "grey lighten-5",
            checkColor: "grey",
          },
          {
            name: "verde",
            colorCode: "#4caf50",
            colorClass: "green",
            checkColor: "white",
          },
          {
            name: "blu",
            colorCode: "#2196f3",
            colorClass: "blu",
            checkColor: "white",
          },
          {
            name: "giallo",
            colorCode: "#FFEB3B",
            colorClass: "yellow",
            checkColor: "grey",
          },
          {
            name: "rosso",
            colorCode: "#f44336",
            colorClass: "red",
            checkColor: "white",
          },
          {
            name: "rosa",
            colorCode: "#F8BBD0",
            colorClass: "pink",
            checkColor: "white",
          },
          {
            name: "arancione",
            colorCode: "#FF9800",
            colorClass: "orange",
            checkColor: "white",
          },
          {
            name: "viola",
            colorCode: "#AB47BC",
            colorClass: "purple",
            checkColor: "white",
          },
          {
            name: "oro",
            colorCode: "#FFD700",
            colorClass: "gold",
            checkColor: "white",
          },
          {
            name: "argento",
            colorCode: "#D3D3D3",
            colorClass: "silver",
            checkColor: "white",
          },
          {
            name: "azzurro",
            colorCode: "#81D4FA",
            colorClass: "light blue",
            checkColor: "white",
          },
          {
            name: "nessun-colore",
            colorCode: "#DFDDDD",
            colorClass: "no color",
            checkColor: "",
          },
        ],
      };
    },
    methods: {
      ...mapMutations([
        "setName",
        "setColoreNome",
        "setColorNameExtAstaDx",
        "setColorNameIntAstaDx",
        "setName",
        "setNameIntAstaDx",
        "setNameExtAstaDx",
      ]),
      setActiveColor(c) {
        this.activeColor = c;

        this.colore = c;
      },

      // eslint-disable-next-line no-unused-vars
      setNomeIncis(i) {
        this.incisione = i;
        this.colore = "";
        this.nome = "";
        this.setName("");
        this.setNameIntAstaDx("");
        this.setNameExtAstaDx("");
        this.setColoreNome("");
        this.setColorNameExtAstaDx("");
        this.setColorNameIntAstaDx("");

        this.activeColor = "";
      },
      // setNomeIncisEstAstaDx(n) {},
      // setNomeIncisTopAstaDx(n) {},
    },
    computed: {
      ...mapState([
        "nome",
        "nomeExtAstaDx",
        "nomeIntAstaDx",
        "coloreNome",
        "coloreNomeExtAstaDx",
        "coloreNomeIntAstaDx",
      ]),
      isActive() {
        return this.menuItem == "name";
      },

      testo: {
        get() {
          if (this.incisione == "incisFrontale") {
            return this.nome;
          } else if (this.incisione == "incisEstAstaDx") {
            return this.nomeExtAstaDx;
          } else if (this.incisione == "incisTopAstaDx") {
            return this.nomeIntAstaDx;
          }
          return "";
        },
        set(n) {
          if (this.incisione == "incisFrontale") {
            this.setName(n);
            this.setNameIntAstaDx("");
            this.setNameExtAstaDx("");
          } else if (this.incisione == "incisEstAstaDx") {
            this.setNameExtAstaDx(n);
            this.setName("");
            this.setNameIntAstaDx("");
          } else if (this.incisione == "incisTopAstaDx") {
            this.setNameIntAstaDx(n);
            this.setName("");
            this.setNameExtAstaDx("");
          }
        },
      },
      colore: {
        get() {
          if (this.incisione == "incisFrontale") {
            return this.coloreNome;
          } else if (this.incisione == "incisEstAstaDx") {
            return this.coloreNomeExtAstaDx;
          } else if (this.incisione == "incisTopAstaDx") {
            return this.coloreNomeIntAstaDx;
          }
          return "";
        },
        set(c) {
          if (this.incisione == "incisFrontale") {
            this.setColoreNome(c.name);
            this.setColorNameExtAstaDx("");
            this.setColorNameIntAstaDx("");
          } else if (this.incisione == "incisEstAstaDx") {
            this.setColorNameExtAstaDx(c.name);
            this.setColoreNome("");
            this.setColorNameIntAstaDx("");
          } else if (this.incisione == "incisTopAstaDx") {
            this.setColorNameIntAstaDx(c.name);
            this.setColoreNome("");
            this.setColorNameExtAstaDx("");
          }
        },
      },
    },

    watch: {
      // colore(newVal) {
      //   if (!_.isEmpty(this.colore) && newVal) {
      //     let color = jslinq(this.colors)
      //       .where((c) => {
      //         return c.name == newVal;
      //       })
      //       .toList();
      //     if (color.length > 0) {
      //       this.setActiveColor(color[0]);
      //     }
      //   }
      // },
    },
  };
</script>

<style lang="less" >
  #personal-name {
    #personal-name-card {
      .el-button-group {
        .el-button {
          font-size: 12px !important;
          padding: 10px 14px;
          &.isActive {
            background-color: @--color-primary;
            color: @--color-text-primary;
          }
        }
      }

      .bordered {
        border: solid @--color-primary 1px;
        &.active {
          border: solid @--color-text-primary 3px;
        }
      }
      .oro {
        background: rgb(205, 192, 0);
        background: linear-gradient(
          90deg,
          rgba(162, 143, 4, 1) 0%,
          rgba(255, 249, 154, 1) 50%,
          rgba(162, 143, 4, 1) 100%
        );
      }

      .argento {
        background: rgb(182, 182, 182);
        background: linear-gradient(
          90deg,
          rgba(182, 182, 182, 1) 0%,
          rgba(255, 255, 255, 1) 50%,
          rgba(182, 182, 182, 1) 100%
        );
      }
      .nc {
        background: transparent;
        border: solid @--color-primary 1px;
        color: transparent;
        &.active {
          border: solid @--color-text-primary 3px;
          background: rgba(51, 51, 51, 0.205) !important;
          color: @--color-text-primary;
        }
      }

      @media (max-width: 992px) {
        .preview {
          max-width: 40%;
        }
      }

      @media (min-width: 993px) {
        .preview {
          max-width: 80%;
        }
      }

      .preview {
        margin: auto;
        margin-top: 10px;
        margin-bottom: 0px;
      }

      .no-colore {
        font-size: 10px;
        width: 21px;
        height: 27px;
        left: -9px;
      }
    }
  }
</style>