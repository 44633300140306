<template>
  <div id="frontale">
    <el-collapse class="nested" @change="emitChange" accordion>
      <el-collapse-item title="Modello" name="frontModello">
        <template slot="title">
          <div class="front-title">
            <div class="content">
              <span>Modello</span>
              <span>{{ frontale.ModelName }}</span>
            </div>
            <div v-if="modelError != ''" class="error">{{ modelError }}</div>
          </div>
        </template>
        <div class="collapse-item-header">
          <el-card class="box-card" shadow="never">
            <div slot="header" class="clearfix">
              <el-input v-model="find" placeholder="CERCA" suffix-icon="el-icon-search"></el-input>
            </div>
            <el-card shadow="never" class="model-items" v-for="frontal in filteredFront"
              :class="{ active: frontal.ItemCode == frontale.ItemCode }" :key="frontal.U_THE_MODELLO">
              <img @click="setFront(frontal)" :src="modelImageUrl(frontal.U_THE_MODELLO)"
                style="cursor: pointer;width: 100%;" />
              <span style="cursor: pointer" @click="setFront(frontal)">
                {{
                  frontal.ModelName
                }}
              </span>
            </el-card>
          </el-card>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Misura" name="frontMisura">
        <template slot="title">
          <div class="size-title">
            <div class="content">
              <span>Misura</span>
              <span>{{ misura }}</span>
            </div>
            <div v-if="sizeError != ''" class="error">{{ sizeError }}</div>
          </div>
        </template>
        <div class="divGroupButton">
          <el-radio-group v-model="misura" class="persGroupButton">
            <el-radio-button v-for="size in availablesSizes" :key="size.size_description" class="persButton"
              type="default" :label="parseInt(size.size_eye) + '/' + parseInt(size.size_bridge)
                "></el-radio-button>
          </el-radio-group>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Colore" name="frontColore">
        <template slot="title">
          <div class="color-title">
            <div class="content">
              <span>Colore</span>
              <span v-if="activeColor">{{ activeColor.U_THE_COLORE.replace('PL', '') }}</span>
            </div>
            <div v-if="colorError != ''" class="error">{{ colorError }}</div>
          </div>
        </template>
        <div class="collapse-item-header color-height">
          <el-card shadow="never">
            <div slot="header" class="head-filters">
              <el-row>
                <el-col :span="24">
                  <el-input v-model="colorName" placeholder="CERCA" class="full-size"
                    suffix-icon="el-icon-search"></el-input>
                </el-col>
              </el-row>
              <div class="select-filters">
                <el-select v-model="categorie" multiple collapse-tags placeholder="SCEGLI CATEGORIA">
                  <el-option v-for="(c, i) in categories" :key="i" :value="c">
                    {{
                      c
                    }}
                  </el-option>
                </el-select>
                <el-select v-model="coloriPrimari" multiple collapse-tags placeholder="FILTRO COLORE">
                  <el-option v-for="(colCat, i) in availableColorsCategories" :key="i" :value="colCat.nome">{{ colCat.nome
                    | toUpper }}</el-option>
                </el-select>
              </div>
            </div>
            <div class="color-item" v-for="c in availablesColors" :key="c.ItemCode"
              :class="{ active: c.ItemCode == activeColor.ItemCode }">
              <el-image :src="baseUrlColor + c.U_THE_COLORE + '.jpg'" fit="fill"
                @click="(c.GiacIta < 1 && !c.ShipDateIta) ? null : handleColorClick(c)">
                <div slot="error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <div v-if="c.GiacIta < 1 && !c.ShipDateIta" class="color-finished">{{ $t('message.Colore_esaurito')
              }}</div>
              <span v-if="c.ItemCode == activeColor.ItemCode">
                {{
                  c.U_THE_COLORE.replace('PL', '')
                }}
              </span>
              <span class="hover transparent" v-else @click="handleColorClick(c)">
                {{
                  c.U_THE_COLORE.replace('PL', '')
                }}
              </span>
            </div>
          </el-card>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Finitura" name="frontFinitura">
        <template slot="title">
          <div class="color-title">
            <div class="content">
              <span>Finitura</span>
              <span v-if="finitura == 'S'">LUCIDO</span>
              <span v-else-if="finitura == 'M'">OPACO</span>
              <span v-else>NATURALE</span>
            </div>
          </div>
          <div v-if="finishingError != ''" class="error">{{ finishingError }}</div>
        </template>
        <div class="divGroupButton">
          <el-radio-group v-model="finitura" class="persGroupButton">
            <el-radio-button label="S">LUCIDO</el-radio-button>
            <el-radio-button label="M">OPACO</el-radio-button>
            <el-radio-button label="N">NATURALE</el-radio-button>
          </el-radio-group>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import _ from "lodash";
import jslinq from "jslinq";
import colorCategories from "./categorieColore";
import { Message } from 'element-ui';

export default {
  props: ["isActive"],
  filters: {
    toUpper: (c) => {
      return c.toUpperCase();
    },
  },
  data() {
    return {
      categorie: [],
      coloriPrimari: [],
      find: "",
      colorName: "",
      images: {
        glassIcon: require("../../../assets/images/OV-806C.png"),
      },
      modelError: "",
      sizeError: "",
      colorError: "",
      finishingError: "",
    };
  },
  mounted: async function () {
    // console.log("ROUTE", this.$route.params.id);
    if (this.$route.params.id === undefined && _.isEmpty(this.frontale)) {
      this.getItems();
    }
    // await this.getAllModelsSizes();
    if (_.isEmpty(this.activeColor)) {
      this.getColors();
    }
  },
  methods: {
    ...mapActions("frontali", ["getItems", "getAllModelsSizes", "getColors"]),
    ...mapMutations([
      "setFrontal",
      "setSize",
      "setFinishing",
      "setColore",
      "clearSize",
    ]),
    emitChange(val) {
      this.$emit("change", val);
    },
    modelImageUrl(model) {
      return "https://media.thema-optical.com/3d_models/" + model + ".png";
    },
    setFront(val) {
      this.setFrontal(val);
    },
    handleColorClick(c) {
      if (parseInt(c.GiacIta) < 1) {
        Message({
          showClose: true,
          message: this.$t('message.Il_colore_{color}_è_esaurito,_data_di_arrivo:_{date}', {
            color: c.U_THE_COLORE,
            date: this.formatDate(new Date(c.ShipDateIta))
          }).toString(),
          type: "error",
          duration: 10000,
        });
      }
      this.setColore(c);
    },
    formatDate(date) {
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero based
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    validate() {
      if (this.errors.length > 0) {
        let errorsQuery = jslinq(this.errors);

        let modelError = errorsQuery.firstOrDefault((e) => {
          return e.label == "model";
        }),
          sizeError = errorsQuery.firstOrDefault((e) => {
            return e.label == "size";
          }),
          colorError = errorsQuery.firstOrDefault((e) => {
            return e.label == "color";
          }),
          finishingError = errorsQuery.firstOrDefault((e) => {
            return e.label == "finishing";
          });

        if (modelError) {
          this.modelError = modelError.message;
        } else {
          this.modelError = "";
        }

        if (sizeError) {
          this.sizeError = sizeError.message;
        } else {
          this.sizeError = "";
        }

        if (colorError) {
          this.colorError = colorError.message;
        } else {
          this.colorError = "";
        }

        if (finishingError) {
          this.finishingError = finishingError.message;
        } else {
          this.finishingError = "";
        }
      } else {
        this.sizeError = this.modelError = this.colorError = "";
      }
      this.$emit("validate");
    },
  },

  computed: {
    ...mapState("frontali", {
      frontals: "items",
      sizes: "itemsSizes",
      colors: "colors",
    }),
    ...mapState({
      frontale: "frontale",
      calibro: "calibro",
      naso: "naso",
      activeColor: "colore",
    }),
    ...mapGetters("frontali", { errors: "checkErrors" }),
    queryFront() {
      return jslinq(this.frontals);
    },
    queryFrontSize() {
      return jslinq(this.sizes);
    },
    queryColors() {
      return jslinq(this.colors);
    },
    baseUrlColor() {
      return `${process.env.VUE_APP_COLORS_URL}/frontali/`;
    },
    filteredFront() {
      if (this.find && this.find != "") {
        return this.queryFront
          .where((c) => {
            return c.ModelName.indexOf(this.find) >= 0;
          })
          .toList();
      } else {
        let c = this.frontals;
        c = c.sort(function (a, b) {
          return a.ModelName.slice(3) - b.ModelName.slice(3);
        });
        return c;
      }
    },
    categories() {
      return this.queryColors
        .select((c) => {
          return c.Category;
        })
        .distinct()
        .toList();
    },
    availablesSizes() {
      if (!_.isEmpty(this.frontale)) {
        return this.queryFrontSize
          .where((c) => {
            return c.U_THE_MODELLO == this.frontale.U_THE_MODELLO;
          })
          .toList();
      }

      return [];
    },
    availablesColors() {
      let col = this.queryColors;
      if (this.categorie.length > 0) {
        col = col.where((c) => {
          return this.categorie.includes(c.Category);
        });
      }

      if (this.colorName != "") {
        col = col.where((c) => {
          return c.U_THE_COLORE.indexOf(this.colorName) >= 0;
        });
      }

      if (this.coloriPrimari.length > 0) {
        let colCat = jslinq(this.availableColorsCategories)
          .where((c) => {
            return this.coloriPrimari.indexOf(c.nome) >= 0;
          })
          .toList();
        col = col.where((c) => {
          let valid = false;
          for (let i = 0; i < colCat.length; i++) {
            const element = colCat[i];
            let o = parseInt(c.Order);
            if (o <= element.max && o >= element.min) {
              valid = true;
              break;
            }
          }
          return valid;
        });
      }

      // return col.take(30).toList();
      return col.toList();
    },
    availableColorsCategories() {
      let cc = this.queryColors
        .orderByDescending((c) => c.Order)
        .select((c) => {
          return c.Order;
        })
        .distinct()
        .toList();

      return jslinq(colorCategories)
        .where((cCat) => {
          let valid = false;
          for (let i = 0; i < cc.length; i++) {
            const element = parseInt(cc[i]);
            if (element <= cCat.max && element >= cCat.min) {
              valid = true;
              break;
            }
          }
          return valid;
        })
        .toList();
    },
    misura: {
      get() {
        if (this.calibro == "" || this.naso == "") {
          return "";
        } else {
          return this.calibro + "/" + this.naso;
        }
      },
      set(val) {
        let s = val.split("/");
        let c = this.queryFrontSize.firstOrDefault((e) => {
          return (
            e.U_THE_MODELLO == this.frontale.U_THE_MODELLO &&
            parseInt(e.size_eye) == s[0] &&
            parseInt(e.size_bridge) == s[1]
          );
        });
        this.setSize(c);
      },
    },
    finitura: {
      get() {
        return this.$store.state.finitura;
      },
      set(val) {
        this.setFinishing(val);
      },
    },
  },
  watch: {
    isActive(newVal, oldVal) {
      // il menù è stato chiuso
      if (oldVal) {
        this.validate();
      }
    },
    // frontale() {
    //   this.validate();
    // },
    async frontale(newVal) {
      if (newVal) {
        // console.log("frontale", this.frontale);
        if (!_.isEmpty(this.frontale)) {
          await this.getAllModelsSizes();
          let a = this.queryFrontSize
            .where((c) => {
              return (
                c.U_THE_MODELLO == this.frontale.U_THE_MODELLO &&
                parseInt(c.size_eye) == this.calibro &&
                parseInt(c.size_bridge) == this.naso
              );
            })
            .toList();
          if (a.length == 0) {
            this.clearSize();
          } else {
            this.setSize(a[0]);
          }
          this.validate();
        }
      }
    },
    misura() {
      this.validate();
    },
    activeColor() {
      if (this.activeColor.Category == "MULTISTRATO") {
        this.dialogMultistrato = true;
      } else {
        this.dialogMultistrato = false;
      }
      this.validate();
    },
    finitura() {
      this.validate();
    },
  },
};
</script>

<style lang='less' >
#frontale {
  .color-finished {
    rotate: 0;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    font-size: 10px;
    font-weight: bold;
    color: mediumvioletred;
    line-height: 10px;
    background-color: rgba(255, 255, 255, 0.795);
    display: flex;
    flex-direction: column;
    justify-content: center;
    cursor: not-allowed;
  }

  .color-height {
    height: 500px;
    overflow-y: auto;
  }

  .model-items {
    display: inline-flex;
    padding: 5px;
    box-sizing: border-box;
    width: 25%;

    .el-card__body {
      flex-direction: column;
    }
  }

  .head-filters {
    &>* {
      margin-top: 8px;
    }

    .select-filters {
      display: flex;

      :first-child {
        margin-right: 4px;
      }
    }
  }
}
</style>