import { mapState } from "vuex";
import * as Metal from "./metal_materials.js";

// import * as Plastic from '../../plastic_materials.js'

export default {
  ...mapState({ id_for_url: "id_for_url", qrcode: "qrcode" }),

  isLoading() {
    if (this.front && this.front.children) {
      // console.log("this.front.children", this.front.children);
      return this.front.children <= 4;
    }
    return false;
  },
  templesMaterial() {
    if (this.templesColor.METALTEMPLE == 0) {
      return "P";
      // il materiale dei pins diventa silver
      // aggiorno la geometria
    }
    if (this.templesColor.METALTEMPLE == 1) {
      if (this.coloreMetalloAste == "PRO0000272") {
        //GUN
        this.pins_material = Metal.metal_gun_material;
        this.pins_material.needsUpdate = true;
        this.pin_dx.needsUpdate = true;
        this.pin_sx.needsUpdate = true;
      } else {
        //GOLD
        this.pins_material = Metal.metal_gold_material;
        this.pins_material.needsUpdate = true;
        this.pin_dx.needsUpdate = true;
        this.pin_sx.needsUpdate = true;
      }
      return "M";
    }
  },
  dialogMultistrato() {
    if (this.$store.state.colore.Category == "MULTISTRATO") {
      return true;
    } else {
      return false;
    }
  },
};
