import Vue from "vue";
import VueRouter from "vue-router";
import VueCookies from "vue-cookies";
// import Home from '../views/Home.vue'
import Configuratore from "../views/Configuratore.vue";

Vue.use(VueCookies);
Vue.use(VueRouter);

const cookies = Vue.$cookies;

const routes = [
  {
    path: "/:id(\\d+)?",
    // path: "/",
    name: "Configuratore",
    component: Configuratore,
  },
  {
    path: "/carrello",
    name: "Carrello",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "carrello" */ "../views/Carrello.vue"),
  },
  {
    path: "/storico",
    name: "Storico Ordini",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "storico" */ "../views/StoricoOrdini.vue"),
  },
  {
    path: "/store-locator",
    name: "Store Locator",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "locator" */ "../views/StoreLocator.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  let auth = cookies.isKey("_identity");
  if (!auth && (to.path == "/storico" || to.path == "/carrello"))
    next({ path: "/" });
  else next();
});

export default router;
