<template>
  <div id="menu-aste">
    <el-collapse @change="emitChange" accordion class="nested">
      <el-collapse-item name="templesMisura">
        <template slot="title">
          <div class="size-title">
            <div class="content">
              <span>Misura</span>
              <span>{{ misuraAste }}</span>
            </div>
            <div v-if="sizeError != ''" class="error">{{ sizeError }}</div>
          </div>
        </template>
        <div class="menu-content">
          <el-radio-group v-model="misuraAste" class="persGroupButton">
            <el-radio-button
              v-for="(size, i) in sizes"
              :key="i"
              class="persButton"
              type="default"
              :label="size"
            ></el-radio-button>
          </el-radio-group>
        </div>
      </el-collapse-item>
      <el-collapse-item name="templesColore">
        <template slot="title">
          <div class="color-title">
            <div class="content">
              <span>Colore</span>
              <span v-if="activeColor">{{ activeColor.U_THE_COLORE.slice(2) }}</span>
            </div>
            <div v-if="colorError != ''" class="error">{{ colorError }}</div>
          </div>
        </template>
        <div class="collapse-item-header menu-content">
          <el-card shadow="never">
            <div
              class="color-item"
              :class="{ active: color.ItemCode == activeColor.ItemCode }"
              v-for="color in availablesColors"
              :key="color.ItemCode"
            >
              <el-image
                :src="baseUrlColor + color.U_THE_COLORE + '.jpg'"
                fit="fill"
                @click="setTemplesColor(color)"
              >
                <div slot="error">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
              <span v-if="color.ItemCode == activeColor.ItemCode">
                {{
                color.U_THE_COLORE.slice(2)
                }}
              </span>
              <span
                class="hover transparent"
                v-else
                @click="setTemplesColor(color)"
              >
                {{
                color.U_THE_COLORE.slice(2)
                }}
              </span>
            </div>
          </el-card>
        </div>
      </el-collapse-item>
      <el-collapse-item title="Finitura" name="templeFinitura">
        <template slot="title">
          <div class="color-title">
            <div class="content">
              <span>Finitura</span>
              <span>{{ finitura == "S" ? "LUCIDO" : "OPACO" }}</span>
            </div>
            <div v-if="finishingError != ''" class="error">{{ finishingError }}</div>
          </div>
        </template>
        <div class="menu-content">
          <el-radio-group v-model="finitura" class="persGroupButton">
            <el-radio-button label="S">LUCIDO</el-radio-button>
            <el-radio-button label="M">OPACO</el-radio-button>
          </el-radio-group>
        </div>
      </el-collapse-item>
    </el-collapse>
  </div>
</template>

<script>
  import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
  import _ from "lodash";
  import jslinq from "jslinq";

  export default {
    props: ["isActive"],
    data: function () {
      return {
        // size: "",
        sizeError: "",
        colorError: "",
        finishingError: "",
      };
    },
    mounted: async function () {
      if (_.isEmpty(this.colors)) {
        this.getAllColors({activeColor: ""});
      }
    },
    methods: {
      ...mapActions("aste", ["getAllColors"]),
      ...mapMutations([
        "setTemplesColor",
        "setTemplesFinishing",
        "setTemplesSize",
      ]),
      emitChange(val) {
        this.$emit("change", val);
      },
      validate() {
        if (_.isEmpty(this.misuraAste)) {
          this.sizeError = "La misura delle aste è obbligatoria";
        } else {
          this.sizeError = "";
        }

        if (this.errors.length > 0) {
          let errorsQuery = jslinq(this.errors);

          let finishingError = errorsQuery.firstOrDefault((e) => {
            return e.label == "finishing";
          });
          let colorError = errorsQuery.firstOrDefault((e) => {
            return e.label == "color";
          });

          if (finishingError) {
            this.finishingError = finishingError.message;
          } else {
            this.finishingError = "";
          }
          if (colorError) {
            this.colorError = colorError.message;
          } else {
            this.colorError = "";
          }
        } else {
          this.finishingError = "";
          this.colorError = "";
        }

        this.$emit("validate");
      },
    },
    computed: {
      ...mapState("aste", { colors: "allColors" }),
      ...mapState({ activeColor: "coloreAste" }),
      ...mapGetters("aste", { errors: "checkErrors" }),
      baseUrlColor() {
        return `${process.env.VUE_APP_COLORS_URL}/frontali/`;
      },
      queryColors() {
        return jslinq(this.colors);
      },
      sizes() {
        return this.queryColors
          .select((el) => {
            return el.U_THE_LUNGASTA;
          })
          .distinct()
          .toList();
      },
      availablesColors() {
        return this.queryColors
          .where((c) => {
            let isValid = true;
            if (!_.isEmpty(this.misuraAste)) {
              isValid = isValid && c.U_THE_LUNGASTA == this.misuraAste;
            }
            return isValid;
          })
          .toList();
      },
      misuraAste: {
        get() {
          return this.$store.state.misuraAste;
        },
        set(val) {
          this.setTemplesSize(val);
        },
      },
      finitura: {
        get() {
          return this.$store.state.finituraAste;
        },
        set(val) {
          this.setTemplesFinishing(val);
        },
      },
    },
    watch: {
      /**
       * Sto in ascolto sull'apertura/chiusura del menu aste
       */
      isActive(newVal, oldVal) {
        // newVal == true il menu è stato aperto
        // if (newVal && _.isEmpty(this.colors)) {
        //   this.getAllColors();
        // }
        // il menù è stato chiuso
        if (oldVal) {
          this.validate();
        }
      },
      activeColor(newVal) {
        this.misuraAste = newVal.U_THE_LUNGASTA;
        this.validate();
        // this.$emit("change");
      },
      async misuraAste(newVal) {
        if (!_.isEmpty(this.activeColor)) {
          await this.getAllColors({ activeColor: this.activeColor.U_THE_COLORE });
          let color = this.queryColors.firstOrDefault((c) => {
            return (
              c.U_THE_LUNGASTA == newVal &&
              c.U_THE_COLORE == this.activeColor.U_THE_COLORE
            );
          });
          this.setTemplesColor(color);
        }
        this.validate();
        // this.$emit("change");
      },
    },
  };
</script>

<style lang="less" scoped>
</style>