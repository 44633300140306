import Vue from "vue";
import "./plugins/axios";
import VueI18n from "vue-i18n";
import ajax from "./plugins/ajax_request";
import App from "./App.vue";
import "animate.css/animate.min.css";
import VueWaypoint from "vue-waypoint";
import router from "./router";
import store from "./store";
import Element from "element-ui";
import "./theme/index.css";
import "./theme/suisseoptical.less";
import VueCookie from "vue-cookies";
import locale from "element-ui/lib/locale/lang/it";

Vue.use(VueI18n);

Vue.use(VueWaypoint);
Vue.use(Element, { locale });
Vue.use(VueCookie);

// Vue.config.productionTip = false;./components/Configurator

Vue.filter("traduciStato", (m) => {
  if (m == 'NEW') {
    return 'NUOVO';
  } else if (m == 'CONFIRMED') {
    return 'CONFERMATO'
  } else if (m == 'PRODUCING') {
    return 'IN PRODUZIONE'
  } else if (m == 'DONE') {
    return 'FINITO'
  } else if (m == 'SHIPPED') {
    return 'SPEDITO'
  } else if (m == 'CANCELED') {
    return 'CANCELLATO'
  } else if (m == 'CUTTING') {
    return 'TAGLIO'
  } else if (m == 'BENDING') {
    return 'MENISCATURA'
  } else if (m == 'TUMBLING') {
    return 'BURATTATURA'
  } else if (m == 'FINISHING') {
    return 'CONFERMATO'
  }
});

const i18n = new VueI18n({
  locale: "IT",
  fallbackLocale: "EN",
});

ajax.getCountryCode().then((response) => {
  i18n.locale = response.data;
});

ajax.getTranslations().then((response) => {
  let messages = response.data;
  for (let key in messages) {
    i18n.setLocaleMessage(key, messages[key]);
  }
});

// eslint-disable-next-line no-unused-vars
window.soconfig = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");

/*new Vue({
  i18n,
  el: '#app',
  ...App
})*/
