import * as Metal from "./metal_materials.js";
// import * as Plastic from '../../plastic_materials.js'
// import * as THREE from "three";
// import _ from 'lodash';

export default {
  frontModel: function () {
    //rimuovo i componenti dalla scena
    this.scene.remove(this.front);

    //this.updateTemplesGeometry = true;

    //carico i componenti nella scena
    this.loadingGeometry = 5; //start loading
    this.loadFront();
  },
  frontColor: function () {
    if (this.getActiveConfiguration.colore.Category == "MULTISTRATO") {
      if (this.controls.maxAzimuthAngle !== Math.PI / 4) {
        this.camera.position.z = 400;
        this.camera.position.y = 60;
        this.camera.position.x = 150;
      }

      this.controls.maxAzimuthAngle = Math.PI / 6;
      this.controls.minAzimuthAngle = (Math.PI / 6) * -1;

      // this.doRender();
      this.$store.state.renderContinuos = true;
      this.doRender();
      // let self = this;
      setTimeout(function () {
        this.$store.state.renderContinuos = false;
      }, 3000);
    } else {
      this.controls.maxAzimuthAngle = Math.PI * 2;
      this.controls.minAzimuthAngle = Math.PI * -2;
    }

    this.setFrontalTexture();
  },
  finitura() {
    //console.log(this.finitura)
    this.setFrontalFinishing();
  },
  finituraAste() {
    this.setTemplesFinishing();
  },
  templesColor(newVal) {
    if (newVal.METALTEMPLE == 0) {
      this.pin_sx.material = Metal.metal_silver;
      this.pin_dx.material = Metal.metal_silver;
    } else {
      if (this.coloreMetalloAste == "PRO0000272") {
        //GUN
        this.pin_sx.material = Metal.metal_gun_material;
        this.pin_dx.material = Metal.metal_gun_material;
      } else if (newVal == "PRO0000280") {
        //GOLD
        this.pin_sx.material = Metal.metal_gold_material;
        this.pin_dx.material = Metal.metal_gold_material;
      }
    }
    this.setTemplesTexture();
  },
  templesMaterial() {
    if (typeof this.front.remove == 'function') {
      this.front.remove(this.temple_dx);
      this.front.remove(this.temple_sx);
      this.front.remove(this.pin_sx);
      this.front.remove(this.pin_dx);
      this.updateTemplesGeometry = true;
      this.loadTemples();
    }

  },
  coloreMetalloAste(newVal, oldVal) {
    if (newVal == "PRO0000272" && oldVal) {
      //&& oldVal
      //GUN
      // console.log("PRO0000272", "watch col met aste");
      this.pin_sx.material = Metal.metal_gun_material;
      this.pin_dx.material = Metal.metal_gun_material;
    } else if (newVal == "PRO0000280" && oldVal) {
      //&& oldVal
      //GOLD
      // console.log("PRO0000280", "watch col met aste");
      this.pin_sx.material = Metal.metal_gold_material;
      this.pin_dx.material = Metal.metal_gold_material;
    }

    //this.pins_material.needsUpdate = true
    this.pin_dx.needsUpdate = true;
    this.pin_sx.needsUpdate = true;
    //this.front.needsUpdate = true
    this.doRender();
  },
  print() {
    /**
     * osservazione variabile print
     * per eseguire il metodo printFocus
     */
    if (this.print == true) {
      this.printFocus();
    }
  },

  setCanvasPos(newVal) {
    if (newVal) {
      this.$store.state.renderContinuos = true;
      this.doRender();
      let self = this;
      setTimeout(function () {
        self.$store.state.renderContinuos = false;
        self.$emit("settedPosition");
        // console.log("self.$store", self.$store);
      }, 3000);
      // this.camera.position.lerp(new THREE.Vector3(135, 104, 304), 0.2);
    }
  },
  resetMaterial(newVal) {
    if (newVal) {
      let self = this;
      for (let i = 0; i < self.front_material.length; i++) {
        const e = self.front_material[i];
        e.map = null;
      }
      for (let i = 0; i < self.temples_material.length; i++) {
        const element = self.temples_material[i];
        if (matchMedia && typeof matchMedia.dispose == 'function')
          matchMedia.dispose();
        element.map = null;
        element.transparent = false;
      }
      this.doRender();
      this.$emit("resetVarResetMaterial");
    }
  },
};
