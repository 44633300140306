<template>
  <div id="note">
    <el-collapse-item name="note" class="main-item">
      <template slot="title">
        <div class="note-title main">
          <div class="content">
            <div class="rect" :class="{ isActive: isActive }">
              <span class="number" :class="{ isActive: isActive }">4</span>
            </div>
            <span>NOTE</span>
          </div>
        </div>
      </template>
      <div class="input-note menu-content">
        <el-input type="textarea" :rows="20" placeholder="Note" v-model="note"></el-input>
      </div>
    </el-collapse-item>
  </div>
</template>

<script>
  import { mapMutations } from "vuex";
  // import { mapState } from "vuex";
  export default {
    props: ["menuItem"],

    methods: {
      ...mapMutations(["setRowNotes"]),
    },

    computed: {
      isActive() {
        return this.menuItem == "note";
      },

      note: {
        get() {
          return this.$store.state.note;
        },
        set(val) {
          this.setRowNotes(val);
        },
      },
    },
  };
</script>

<style>
</style>