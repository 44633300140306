<template>
  <div class="configuratore">
    <el-container v-if="windowWidth < 992" direction="vertical">
      <el-main class="main-class">
        <!-- <Canvas
          :setCanvasPos="setCanvasPos"
          @settedPosition="setCanvasPos = false"
          @settedFront="$store.state.animateToFront = false"
          @settedTemple="$store.state.animateToTemple = false"
        />-->
        <Canvas
          :resetMaterial="resetMaterial"
          :setCanvasPos="setCanvasPos"
          @settedPosition="setCanvasPos = false"
        />
      </el-main>
      <div style="height: 1px; border-bottom: solid #753bbd 1px !important;"></div>
      <el-aside class="aside-class">
        <Menu
          @setPositionForPrint="setCanvasPos = true"
          @resettedMaterial="resetForMaterial"
        />
      </el-aside>
    </el-container>
    <el-container v-else>
      <el-main class="main-class">
        <Canvas
          :resetMaterial="resetMaterial"
          :setCanvasPos="setCanvasPos"
          @settedPosition="setCanvasPos = false"
        />
        <!-- <Canvas
          :setCanvasPos="setCanvasPos"
          @settedPosition="setCanvasPos = false"
          @settedFront="$store.state.animateToFront = false"
          @settedTemple="$store.state.animateToTemple = false"
        />-->
      </el-main>
      <div>
        <el-aside class="aside-class">
          <Menu
            @setPositionForPrint="setCanvasPos = true"
            @resettedMaterial="resetForMaterial"
          />
        </el-aside>
      </div>
    </el-container>
  </div>
</template>

<script>
  import componenti from "../components/configuratore";
  // @ is an alias to /src

  export default {
    name: "Configuratore",
    components: componenti,
    data() {
      return {
        setCanvasPos: false,
        resetMaterial: false,
        windowWidth: window.innerWidth,
      };
    },
    methods: {
      resetForMaterial() {
        this.resetMaterial = true;
        setTimeout(() => {
          this.resetMaterial = false;
        }, 3000);
      },
    },
  };
</script>

<style lang="less">
  // .container {
  //   height: 100%;

  //   .el-main {
  //     display: block;
  //     flex: 0;
  //     flex-basis: auto;
  //     overflow: auto;
  //     box-sizing: border-box;
  //   }
  // }

  .main-class {
    width: auto;
    // min-width: 500px;
    // border: solid 2px green;
    // box-sizing: border-box;
    height: auto;
  }
  .aside-class {
    width: 25vw !important;
    min-width: 500px;
    // border: solid 2px green;
    // box-sizing: border-box;
  }

  @media (max-width: 992px) {
    .aside-class {
      width: 100vw !important;
      // min-width: 500px;
      // border: solid 2px green;
      // box-sizing: border-box;
    }
    .main-class {
      // flex: 0 !important;
      width: 100vw !important;
      // min-width: 500px;
      // border: solid 2px green;
      // box-sizing: border-box;
      height: 50vh;
    }
  }
</style>
